
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    SkillGameLevelSelectPage = async () => import(
        /* webpackChunkName: 'skillgame' */
        '../views/SkillGameLevelSelectPage.vue'
    ),
    SkillGameOffersPage = async () => import(
        /* webpackChunkName: 'skillgame' */
        '../views/SkillGameOffersPage.vue'
    ),
    SkillGamePlayPage = async () => import(
        /* webpackChunkName: 'skillgame' */
        '../views/SkillGamePlayPage.vue'
    ),
    SkillGameWorldSelectPage = async () => import(
        /* webpackChunkName: 'skillgame' */
        '../views/SkillGameWorldSelectPage.vue'
    );

export default [
    { path: '/summer-worlds',  component: SkillGameWorldSelectPage },
    { path: '/summer-worlds/weekly-offers', component: SkillGameOffersPage },
    { path: '/summer-worlds/:world',  component: SkillGameLevelSelectPage },
    { path: '/summer-worlds/:world/:stage', component: SkillGamePlayPage, props: true },
    {
        // publicly accessible only during expired
        path: '/summer-worlds',
        name: 'skill-game',
        component: SkillGamePlayPage,
        meta: { activeInPhase: ['expired'], public: true },
        props: {
            world: '4',
            stage: '3',
        },
    },
];
