const getDefaultState = () => ({
    urlParams: {},
    urlParamString: undefined,
});

const state = getDefaultState();

const getters = {
    urlParams: (state) => state.urlParameters,
    urlParamString: (state) => state.urlParamString,
};

const mutations = {
    updateUrlParams (state, data) {
        state.urlParams = { ...data };
    },
    updateUrlParamString (state, data) {
        state.urlParamString = data;
    },
    updatePageViewData (state, data) {
        state.pageViewData = { ...data };
    },
};

const actions = {
    updateUrlParams ({ commit }, urlParams) {
        try {
            commit('updateUrlParams', urlParams);
        }
        catch (err) {
            console.error('error updating', err.message, err);
        }
    },
    updateUrlParamString ({ commit }, urlParamString) {
        try {
            commit('updateUrlParamString', urlParamString);
        }
        catch (err) {
            console.error('error updating', err.message, err);
        }
    },
    updatePageViewData ({ commit }, pageViewData) {
        try {
            commit('updatePageViewData', pageViewData);
        }
        catch (err) {
            console.error('error updating', err.message, err);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
