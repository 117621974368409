import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = { class: "visually-hidden" }

import { useRoute, useRouter } from 'vue-router';
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    import IconArrowBack from '@components/icons/IconArrowBack.vue';
    import SummerGameLogo from '@components/SummerGameLogo.vue';
    import TheMenu from '@components/TheMenu.vue';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { vT } = useI18next(['global', 'links']);

    const h1 = ref();

    const backgroundClass = computed(() =>
        (['landing'].includes(store.state.ui.pageName) ? 'has-background-starbucks-green' : ''));

    const isLight = computed(() => {
        if ((store.state.ui.pageName === 'skill-game-level-select' && ['2', '3'].includes(route.params.world)) ||
            ['activities', 'faq', 'oamoe', 'oamoe-limited', 'oamoe-thanks', 'prizes', 'error', 'maintenance', 'skill-game-offers', 'skill-game-play', 'survey'].includes(store.state.ui.pageName)) {
            return false;
        }

        return true;
    });

    const hideLogo = computed(() => (['skill-game-level-select', 'skill-game-play'].includes(store.state.ui.pageName)));

    const hideNav = computed(() => (['faq', 'oamoe', 'survey'].includes(store.state.ui.pageName)));

    const showBackToHome = computed(() => (['oamoe'].includes(store.state.ui.pageName)));

    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    onMounted(() => {
        // Focus the h1 element to force screen readers back to the top after a navigation.
        // This will ensure screen readers will read the page again.
        // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
        router.afterEach(() => {
            if (h1.value) {
                h1.value.focus();
            }
        });
    });

return (_ctx, _cache) => {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["the-header", [backgroundClass.value, { 'has-navigation': loggedIn.value && !hideNav.value }]]),
    role: "banner",
    "data-e2e": "global-header"
  }, [
    (showBackToHome.value)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: "/",
          class: "button icon back-btn"
        }, {
          default: _withCtx(() => [
            _createVNode(IconArrowBack),
            _withDirectives(_createElementVNode("span", _hoisted_1, null, 512), [
              [_unref(vT), 'buttons:back']
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (loggedIn.value && !hideNav.value)
      ? (_openBlock(), _createBlock(TheMenu, {
          key: 1,
          "is-light": isLight.value
        }, null, 8, ["is-light"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      _createVNode(SummerGameLogo, {
        variant: isLight.value ? 'light' : 'dark'
      }, null, 8, ["variant"]),
      _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
        [_unref(vT), 'title']
      ])
    ], 512), [
      [_vShow, !hideLogo.value]
    ])
  ], 2))
}
}

}