
    import { startCase } from 'lodash';
    import { mapState } from 'vuex';

    import { useI18next } from '@composables/i18next';
    import { track } from '@public/js/plugins/WebAnalytics';

    export default {
        name: 'SocialShare',

        i18nOptions: { namespaces: ['share'] },

        props: {
            isLight: {
                type: Boolean,
                default: false,
            },

            shareId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t, vT } = useI18next(['share']);
            return { t, vT };
        },

        data () {
            return {
                isShareSupported: false,
                sharing: false,
            };
        },

        computed: {
            ...mapState(['profile', 'ui']),

            shareCopyNode () {
                return this.t(this.shareId) || this.t('share');
            },
        },

        created () {
            this.isShareSupported = 'share' in navigator;
        },

        methods: {
            async handleShareButtonClick () {
                if (this.sharing) return;
                this.sharing = true;

                // Get share image into blob so it can be shared
                const imageUrl = this.shareCopyNode.image || this.t('share:image');
                const imageName = imageUrl.split('/').pop();
                const imageBlob = await fetch(imageUrl).then(r => r.blob());
                const data = {
                    files: [
                        new File([imageBlob], imageName, {
                            type: imageBlob.type,
                        }),
                    ],
                    title: this.shareCopyNode.title || this.t('share:title'),
                    text: this.shareCopyNode.description || this.t('share:description'),
                    url: this.shareCopyNode.url || this.t('share:url'),
                };

                try {
                    if (navigator.canShare(data)) {
                        await navigator.share(data);
                    }
                    else {
                        // Might still be able to share but without image
                        delete data.files;
                        if (navigator.canShare(data)) {
                            await navigator.share(data);
                        }
                    }

                    const category = startCase(this.ui.modalName ? `${this.ui.modalName} Modal` : this.ui.pageName);
                    track('Share', { category, value: this.profile.id });
                }
                catch (error) {
                    console.error('could not share', error);
                }

                this.sharing = false;
            },
        },
    };
