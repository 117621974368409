
    import { useI18next } from '@composables/i18next';
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        props: {
            error: {
                type: String,
                default: 'default',
            },
        },


        setup () {
            const { t, vT } = useI18next(['error']);
            return { t, vT };
        },

        data () {
            return {
                pageName: 'error',
            };
        },


    };
