import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import { computed } from 'vue';

    import { useI18next } from '@composables/i18next';
    import { resolveWebpackI18nAsset } from '../plugins/I18nAssetsPlugin';

    
export default {
  __name: 'SummerGameLogo',
  props: {
        variant: {
            type: String,
            default: () => 'light',
        },
    },
  setup(__props) {

    const props = __props;

    const { t } = useI18next('global');
    const imageSource = computed(() =>
        resolveWebpackI18nAsset(`img/summer-game-logo-${props.variant}.svg`));

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("img", {
    class: "summer-game-logo",
    src: imageSource.value,
    alt: _unref(t)('summer_game_logo')
  }, null, 8, _hoisted_1))
}
}

}