
const
    OamoePage = async () => import(
        /* webpackChunkName: 'oamoe' */
        './../views/OamoePage.vue'
    ),
    OamoeLimitedPage = async () => import(
        /* webpackChunkName: 'oamoe' */
        './../views/OamoeLimitedPage.vue'
    ),
    OamoeThanksPage = async () => import(
        /* webpackChunkName: 'oamoe' */
        './../views/OamoeThanksPage.vue'
    );

export default [
    { path: '/oamoe', component: OamoePage },
    { path: '/oamoe/limited/:type?', component: OamoeLimitedPage },
    { path: '/oamoe/thanks', component: OamoeThanksPage },
];
