import { ref, computed } from 'vue';
import store from '../store';

const consoleStyle = 'color: orange; font-family: \'Courier\'; font-weight: 400; font-size: 13px;';

export default {
    install (app) {
        app.config.globalProperties.$gds = {
            logGdsMessage,
            trackGdsPageView,
            trackGdsEvent,
        };
    },
};

export function trackGdsPageView (pageData, isLocalDev = _isLocalDev()) {
    const count = ref(0);
    const debug = true;
    const checkLimit = 20;
    const _analytics = ref(window._analytics || {});
    const urlParamString = computed(() => store.state.gds.urlParamString || '');
    const urlParams = computed(() => ({ ...store.state.gds.urlParams }));

    const interval = setInterval(() => {
        count.value += 1;
        if ((_analytics.value && _analytics.value.loaded) || count.value >= checkLimit || isLocalDev) {
            clearInterval(interval);
            try {
                const category = urlParamString.value ? `${pageData.category}?${urlParamString.value}` : pageData.category;
                const screenName = pageData.category;
                pageData.dimensions = pageData.dimensions || {};
                pageData.dimensions.appRegion = store.state.app.userCountry;
                pageData.dimensions.pageTitle = document.title;
                if (urlParamString.value.length > 0) {
                    pageData.dimensions.queryString = urlParamString.value;
                }
                pageData.dimensions.screenName = screenName;
                pageData.dimensions.pageTemplate = screenName;
                pageData.dimensions.pageReferrer = document.referrer;

                const dimensions = { ...pageData.dimensions, ...urlParams.value };

                if (isLocalDev) {
                    if (debug) {
                        console.info('%c[GDS] Bypassing call to GDS server.', consoleStyle);
                        _logPageViewTrackingData(category, { dimensions });
                    }
                }
                else {
                    _analytics.value.trackPageView(category, { dimensions });
                }
            }
            catch (error) {
                console.warn('[GDS] Unable to connect to analytics', error);
            }
        }
    }, 100);
}

export function trackGdsEvent (eventData, isLocalDev = _isLocalDev()) {
    if (!eventData || !eventData.category || !eventData.action) {
        console.error('[GDS] Invalid data object passed.\ntrackGdsEvent(data) arg Object must contain members: category, action.\nAn optional dimensions object may also be included.', eventData);
        return;
    }

    const checkLimit = 20;
    const count = ref(0);
    const _analytics = ref(window._analytics || {});
    const urlParamString = computed(() => store.state.gds.urlParamString || '');

    const interval = setInterval(() => {
        count.value += 1;
        if ((_analytics.value && _analytics.value.loaded) || count.value >= checkLimit || isLocalDev) {
            clearInterval(interval);
            try {
                eventData.dimensions = eventData.dimensions || {};
                eventData.dimensions.appRegion = store.state.app.userCountry;
                eventData.dimensions.pageURL = document.location.href;
                if (urlParamString.value.length > 0) {
                    eventData.dimensions.queryString = urlParamString.value;
                }
                eventData.dimensions.pageTemplate = eventData.category;

                if (isLocalDev) {
                    console.info('%c[GDS] Bypassing call to GDS server.', consoleStyle);
                    _logEventTrackingData(eventData);
                }
                else {
                    const label = null;
                    _analytics.value.trackEvent(eventData.category, eventData.action, label, eventData.dimensions);
                }
            }
            catch (error) {
                console.warn('[GDS] Unable to connect to analytics', error);
            }
        }
    }, 100);
}

function _logPageViewTrackingData (gdsCategory, data) {
    console.info('%c[GDS]', consoleStyle, '**PAGE VIEW**');
    console.log('%c[GDS] category:', consoleStyle, gdsCategory);
    console.log('%c[GDS] dimensions:', consoleStyle, data.dimensions);
}

function _logEventTrackingData (data) {
    console.info('%c[GDS]', consoleStyle, '**EVENT**');
    console.log('%c[GDS] category:', consoleStyle, data.category);
    console.log('%c[GDS] action:', consoleStyle, data.action);
    console.log('%c[GDS] dimensions:', consoleStyle, data.dimensions);
}

function _isLocalDev () {
    return document.location.href.match(/localhost:808/);
}

export function logGdsMessage (messageInfo) {
    if (messageInfo.type === 'error') {
        if (messageInfo.trackingMethod === 'pageView') {
            console.info('%c[GDS] Error firing pageView for ' + messageInfo.item, consoleStyle);
            console.warn(messageInfo.message);
        }
        else {
            console.info('%c[GDS] Error updating global object for ' + messageInfo.item, consoleStyle);
            console.warn(messageInfo.message);
        }
    }
    else {
        console.info(`%c[GDS] Info: ${messageInfo.item} ${messageInfo.message}`, consoleStyle, messageInfo.value ?? '');
    }
}
