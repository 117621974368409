import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error general-page" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      innerHTML: $setup.t([`error:${$props.error}.headline_copy`, 'error:default.headline_copy'])
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: $setup.t([`error:${$props.error}.body_copy`, 'error:default.body_copy'])
    }, null, 8, _hoisted_3),
    _createVNode(_component_RouterLink, {
      to: "/",
      class: "button tertiary"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: $setup.t([`error:${$props.error}.button_copy`, 'error:default.button_copy'])
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    })
  ]))
}