
import axios from 'axios';
import { camelCase, snakeCase } from 'lodash';
import { reactive } from 'vue';

import api from '../../api';

const getDefaultState = () => reactive({
    boosters: {
        areaClear: 0,
        colorClear: 0,
        columnClear: 0,
        rowClear: 0,
    },
    progress: {},
});

const state = getDefaultState();

const getters = {
    highestLevelCompleted (state, getters) {
        let highestLevel = '0-3';

        for (let world = 1; world <= 4; world += 1) {
            for (let stage = 1; stage <= 3; stage += 1) {
                if (getters.isLevelCompleted(`${world}-${stage}`)) {
                    highestLevel = `${world}-${stage}`;
                }
                else {
                    break;
                }
            }
        }

        return highestLevel;
    },

    isLevelCompleted: (state) => (level) => state.progress[level]?.completed || false,

    levelHighScore: (state) => (level) => state.progress[level]?.high_score || 0,

    totalBoosters (state) {
        let total = 0;
        for (const key in state.boosters) {
            total += state.boosters[key];
        }
        return total;
    },
};

const mutations = {
    updateBoosters (state, data) {
        for (const boosterKey in data) {
            const camelizedKey = camelCase(boosterKey.replace('_booster', ''));
            state.boosters[camelizedKey] = data[boosterKey];
        }
    },

    updateProgress (state, data) {
        for (const levelKey in data) {
            state.progress[levelKey] = data[levelKey];
        }
    },

    markLevelCompleted (state, { level }) {
        state.progress[level].completed = true;
    },

    decreaseBooster (state, { booster, amount = 1 } = {}) {
        const camelCaseBooster = camelCase(booster.replace('_booster', ''));
        state.boosters[camelCaseBooster] -= amount;
    },

    increaseAllBoosters (state, { amount }) {
        for (const boosterKey in state.boosters) {
            if (boosterKey !== 'skipLevel' &&
                Object.prototype.hasOwnProperty.call(state.boosters, boosterKey)) {
                state.boosters[boosterKey] += amount;
            }
        }
    },

    increaseBooster (state, { booster, amount = 1 } = {}) {
        const camelCaseBooster = camelCase(booster.replace('_booster', ''));
        state.boosters[camelCaseBooster] += amount;
    },

    updateLevelHighScore (state, { level, score }) {
        state.progress[level].high_score = score;
    },
};

const actions = {
    async loadBoosters ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/boosters`);
            commit('updateBoosters', response.data);
        }
        catch (e) {
            console.error('error getting skill game boosters', e);
        }
    },

    async loadProgress ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/skill-game/progress/me`);
            commit('updateProgress', response.data.results);
        }
        catch (e) {
            console.error('error getting skill game progress', e);
        }
    },

    async reportGameStart ({ dispatch }, data) {
        try {
            await axios.post(`${api.base}/skill-game/start`, data);
            await dispatch('loadBoosters');
        }
        catch (e) {
            console.error('error posting skill game start', e);
        }
    },

    async reportGameEnd ({ commit, getters }, data) {
        try {
            await axios.post(`${api.base}/skill-game/end-game`, data);

            const { level, score, win } = data;
            if (win && !getters.isLevelCompleted(level)) {
                commit('markLevelCompleted', { level });
            }

            if (score > getters.levelHighScore(level)) {
                commit('updateLevelHighScore', { level, score });
            }
        }
        catch (e) {
            console.error('error posting skill game end', e);
        }
    },

    async useBooster ({ commit }, { booster }) {
        let success = false;

        try {
            const snakifiedKey = snakeCase(booster);
            const response = await axios.post(`${api.base}/awards/boost/${snakifiedKey}`);

            success = response.status === 200;

            if (success) {
                commit('decreaseBooster', {
                    booster,
                    amount: 1,
                });
            }
        }
        catch (e) {
            console.error('error using skill game booster', booster, e);
        }

        return success;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
