import store from '../store/index';

const
    SkillGamePlayPage = async () => import(
        /* webpackChunkName: 'skillgame' */
        '../views/SkillGamePlayPage.vue'
    ),
    SurveyPage = async () => import(
        /* webpackChunkName: 'survey' */
        '../views/SurveyPage.vue'
    );

export default [
    {
        path: '/',
        redirect: (to) => {
            if (store.getters['profile/loggedIn']) {
                return '/survey';
            }

            return '/landing';
        },
        meta: { activeInPhase: ['survey'], activeInSurvey: true },
    },
    { path: '/survey', component: SurveyPage, meta: { activeInPhase: ['survey'], activeInSurvey: true } },
    {
        // publicly accessible only during survey
        path: '/summer-worlds',
        component: SkillGamePlayPage,
        meta: { activeInPhase: ['survey'], activeInSurvey: true, public: true },
        props: {
            world: '4',
            stage: '3',
        },
    },
];
