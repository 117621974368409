import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-screen" }
const _hoisted_2 = ["autoplay"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SummerGameLogo = _resolveComponent("SummerGameLogo")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SummerGameLogo, {
      variant: $data.logoVariant,
      class: "starbucks-summer-logo"
    }, null, 8, ["variant"]),
    _createElementVNode("video", {
      class: "loading-animation",
      autoplay: $setup.isAnimationEnabled,
      crossorigin: "anonymous",
      disablepictureinpicture: "",
      disableremoteplayback: "",
      loop: "",
      muted: "",
      playsinline: "",
      preload: ""
    }, [
      _createElementVNode("source", {
        src: `${$data.videoRoot}loader.mov`,
        type: "video/quicktime"
      }, null, 8, _hoisted_3),
      _createElementVNode("source", {
        src: `${$data.videoRoot}loader.webm`,
        type: "video/webm"
      }, null, 8, _hoisted_4)
    ], 8, _hoisted_2),
    _createElementVNode("h2", {
      innerHTML: $setup.t('headline')
    }, null, 8, _hoisted_5)
  ]))
}