
    import axios from 'axios';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import SummerGameLogo from '@components/SummerGameLogo.vue';

    export default {
        name: 'LoadingScreen',

        components: {
            SummerGameLogo,
        },

        emits: ['assets-loaded'],

        setup () {
            const store = useStore();
            const isAnimationEnabled = store.getters['ui/isAnimationEnabled'];
            const { t, vT } = useI18next(['loading_screen']);
            return { t, vT, isAnimationEnabled };
        },

        data () {
            return {
                assetList: [],
                loadingComplete: false,
                videoRoot: '//d1hyi36gq7mg0j.cloudfront.net/starbucks/summer2024/',
                logoVariant: 'light',
            };
        },

        mounted () {
            this.loadAssets();
        },

        methods: {
            close () {
                this.loadingComplete = true;
                this.$emit('assets-loaded');
            },

            compileAssetList (data) {
                if (typeof data === 'string') {
                    this.assetList.push(data);
                }
                else if (Array.isArray(data)) {
                    for (const asset of data) {
                        this.compileAssetList(asset);
                    }
                }
                else if (typeof data === 'object') {
                    for (const key in data) {
                        if (Object.prototype.hasOwnProperty.call(data, key)) {
                            this.compileAssetList(data[key]);
                        }
                    }
                }
                else {
                    console.error('error preloading assets: asset list contains unknown data type');
                }
            },

            loadAsset (path) {
                return new Promise(resolve => {
                    const image = new Image();
                    image.addEventListener('load', () => resolve());
                    image.src = path;
                });
            },

            async loadAssets () {
                try {
                    const response = await axios.get('/public/build/webpack-assets.json');
                    this.compileAssetList(response.data);

                    await Promise.all(this.assetList.map(this.loadAsset.bind(this)));
                    this.close();
                }
                catch (error) {
                    console.error('error preloading assets:', error);
                    this.close();
                }
            },
        },
    };
