import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/social-feed-heart-white.svg'
import _imports_1 from '@public/img/social-feed-heart.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($data.isShareSupported)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "social-share",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.handleShareButtonClick && $options.handleShareButtonClick(...args)), ["prevent"]))
      }, [
        ($props.isLight)
          ? (_openBlock(), _createElementBlock("img", _hoisted_1))
          : (_openBlock(), _createElementBlock("img", _hoisted_2)),
        _createElementVNode("span", {
          class: "visually-hidden",
          innerHTML: $setup.t('share:cta')
        }, null, 8, _hoisted_3)
      ]))
    : _createCommentVNode("", true)
}